import { render, staticRenderFns } from "./NameCell.vue?vue&type=template&id=6b1d5fec&scoped=true&"
import script from "./NameCell.vue?vue&type=script&lang=js&"
export * from "./NameCell.vue?vue&type=script&lang=js&"
import style0 from "./NameCell.vue?vue&type=style&index=0&id=6b1d5fec&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b1d5fec",
  null
  
)

export default component.exports