<template>
  <WTable
    style="height: calc(70vh)"
    max-height="calc(70vh - 100px)"
    :columns="columns"
    :rows="rows"
    :isDisabled="isDisabled"
    :bordered="false"
    :withToggleColumnVisibilityMode="!isOneColMode"
    :line-numbers="!isOneColMode"
    :row-style-class="rowStyleClassFn"
    :sort-options="{
      enabled: true,
    }"
    :pagination-options="{
      enabled: false, // isOneColMode // info: отключена из-за неовместимости с легаси
      remoteMode: true,
      mode: 'pages', // pages, records
      position: 'bottom',
      perPage: 10,
      perPageDropdownEnabled: true,
      perPageDropdown: [5, 10, 20, 40, 50],
      dropdownAllowAll: true,
      jumpFirstOrLast : true,
      firstLabel: $t('general.pagination.firstPage'),
      lastLabel: $t('general.pagination.lastPage'),
      nextLabel: $t('general.pagination.nextPage'),
      prevLabel: $t('general.pagination.prevPage'),
      rowsPerPageLabel: $t('general.pagination.rowsPerPage'),
      pageLabel: $t('general.pagination.page'),
      ofLabel: $t('general.pagination.of'),
      allLabel: $t('general.pagination.all')
    }"
    :isLoading.sync="isDataLoading"
    @update:columns="handleUpdateColumns"
    @on-selected-rows-change="handleSelectedRowsChange"
    @on-select-all="handleSelectedAllRowsChange"
    @on-cell-click="handleCellClick"
    @on-page-change="handlePageChange"
    @on-per-page-change="handlePerPageChange"
    @on-sort-change="onSortChange"
  >

    <template v-slot:table-menu>
      <slot name="table-menu"></slot>
    </template>

    <template v-slot:emptystate>
      <slot name="emptystate"></slot>
    </template>

    <template v-slot:table-row="props">
      <template v-if="props.column.field === 'mac'">
        <MacCell :props="props"
          @on-click-video-mac-cell="handleClickVideoMacCell(props.row.nativeData.data.rtsp_stream)" />
      </template>

      <template v-else-if="props.column.field === 'ip'">
        <IPCell :props="props" />
      </template>

      <template v-else-if="props.column.field === 'authentication'">
        <AuthenticationCell :props="props" />
      </template>

      <template v-else-if="props.column.field === 'description'">
        <DescriptionCell :props="props" />
      </template>

      <template v-else-if="props.column.field === 'cpe'">
        <CPECell :props="props" />
      </template>

      <template v-else-if="props.column.field === 'wlan'">
        <WlanCell :props="props" />
      </template>

      <template v-else>
        {{ props.formattedRow[props.column.field] ? props.formattedRow[props.column.field] : '-' }}
      </template>
    </template>

    <template v-slot:table-actions-bottom v-if="!isOneColMode">
      <slot name="table-actions-bottom"></slot>
    </template>

  </WTable>
</template>

<script>
/**
 * компонент - таблица статистики по clientRF.
 * компонент локальный: используется только в ClientRF.vue
 * подробнее по настройкам и пропсам - см компонент WTable.vue
 * @component
*/

import {
  generateTableColumns,
  generateTableRows
} from './config';

import {
  MacCell,
  IPCell,
  AuthenticationCell,
  DescriptionCell,
  CPECell,
  WlanCell
} from './components';

export default {
  name: 'ClientRFTable',
  components: {
    MacCell,
    IPCell,
    AuthenticationCell,
    DescriptionCell,
    CPECell,
    WlanCell
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    activeRowClientRFMac: {
      type: String,
      default: ''
    },
    selectedRowClientRFIds: {
      type: Array,
      required: true
    },
    isDataLoading: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isOneColMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isTableColumnHidden: {
        mac: false,
        ip: false,
        authentication: false,
        description: false,
        cpe: false,
        wlan: false
      }
    };
  },
  watch: {
  },
  computed: {
    columns: {
      get() {
        return generateTableColumns({
          i18nInstance: this.$i18n,
          hiddenColumnRules: {
            mac: this.isTableColumnHidden.mac,
            ip: this.isTableColumnHidden.ip || this.isOneColMode,
            authentication: this.isTableColumnHidden.authentication || this.isOneColMode,
            description: this.isTableColumnHidden.description || this.isOneColMode,
            cpe: this.isTableColumnHidden.cpe || this.isOneColMode,
            wlan: this.isTableColumnHidden.wlan || this.isOneColMode
          },
          additionalRules: {}
        });
      },
      set(updatedColumns) {
        // обновить таблицу, когда обновились данные в колонках
        // например, какую-то колонку скрыли/раскрыли
        updatedColumns.forEach(el => {
          this.isTableColumnHidden[el.field] = el.hidden;
        });
      }
    },
    rows() {
      return generateTableRows({
        clientRFData: this.items,
        selectedRowClientRFIds: this.selectedRowClientRFIds
      });
    }
  },
  methods: {
    handleUpdateColumns(columns) {
      this.columns = columns;
    },
    rowStyleClassFn(row) {
      const rowMac = row.nativeData.mac;

      return this.activeRowClientRFMac === rowMac ? 'clients-rf-table__active-row' : '';
    },
    handleSelectedAllRowsChange(event) {
      this.$emit('on-select-all', event);
    },
    handleSelectedRowsChange(event) {
      const { selectedRows } = event;
      this.$emit('on-selected-rows-change', selectedRows);
    },
    handleCellClick(event) {
      this.$emit('on-cell-click', event.row);
    },
    handlePageChange(event) {
      this.$emit('on-page-change', event);
    },
    handlePerPageChange(event) {
      this.$emit('on-per-page-change', event);
    },
    onSortChange(event) {
      this.$emit('on-sort-change', event);
    },
    handleClickVideoMacCell(rtspStream) {
      this.$emit('on-open-video-modal', rtspStream);
    }
  },
  created() {
  },
  mounted() {
  }
};
</script>

<style lang="css" scoped>
</style>

<style lang="css">
.clients-rf-table__active-row {
  background: color-mix(in srgb, var(--brand-success) 70%, transparent);
  color: var(--font-color-main);
}

.clients-rf-table__active-row:hover {
  background: color-mix(in srgb, var(--brand-success) 70%, transparent) !important;
  color: var(--font-color-main) !important;
}
</style>
