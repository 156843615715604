/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/**
 * конфигурация таблицы WifiAnalyzerGraphTable.vue
*/

/**
 * генератор колонок таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/column-options.html#label
*/

import {
  getSelectedRowValue
} from './utils';

function generateTableColumns({ i18nInstance, hiddenColumnRules, additionalRules }) {
  const $t = i18nInstance.t.bind(i18nInstance);

  return [
    {
      label: 'BSSID',
      field: 'bssid',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.bssid
    },
    {
      label: 'SSID',
      field: 'ssid',
      width: '150px',
      type: 'text',
      hidden: hiddenColumnRules.ssid
    },
    {
      label: $t('wifiAnalyzer.signal'),
      field: 'signal',
      width: '100px',
      type: 'number',
      hidden: hiddenColumnRules.signal
    },
    {
      label: $t('wlans.security'),
      field: 'security',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.security
    },
    {
      label: $t('wifiAnalyzer.channel'),
      field: 'channel',
      width: 'auto',
      type: 'number',
      hidden: hiddenColumnRules.channel
    },
    {
      label: $t('wifiAnalyzer.quality'),
      field: 'quality',
      width: 'auto',
      type: 'number',
      hidden: hiddenColumnRules.quality
    }
  ];
}

/**
 * генератор строк таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/#rows
 * https://xaksis.github.io/vue-good-table/guide/advanced/grouped-table.html#customizing-header-row
*/
function generateTableRows({ wlansData, selectedRowsBssids }) {
  return wlansData.map(wlan => {
    const {
      additionData,
      bssid,
      center_idx0,
      channel,
      encryption,
      htmodelist,
      mode,
      quality,
      quality_max,
      sec_channel_offset,
      signal,
      ssid
    } = wlan;

    return {
      // nativeData - хранит неформатированные данные. Нужны, чтобы удобно эмитить их по событию для дальнейших манипуляций.
      nativeData: wlan,
      bssid,
      ssid,
      signal,
      security: encryption?.description,
      channel,
      quality,
      // можно ли выбирать строку чекбоксом
      // vgtDisabled: false,
      // выбрана ли строка в чекбоксе исходя из стейта, который контролирует выбранные строки
      vgtSelected: getSelectedRowValue(bssid, selectedRowsBssids)
    };
  });
}

export {
  generateTableColumns,
  generateTableRows
};
