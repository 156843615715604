// selected checkbox of a row or not
function getSelectedRowValue(currentClientId, selectedClientIdsArray) {
  let value = false;

  selectedClientIdsArray.forEach(clientId => {
    if (clientId === currentClientId) {
      value = true;
    }
  });

  return value;
}

export {
  getSelectedRowValue
};
