<template>
  <div v-if="props.formattedRow[props.column.field]"
    class="cpe-cell"
  >
    <div class="cpe-cell-block cpe-cell-block_main">
      <span
        @click.stop="handleCpeNameClick"
        class="badge badge-light cpe-cell-badge"
        :class="[cpeName ? 'badge-primary' : 'badge-default']"
      >
        <img class="router-icon"
          src="/static/img/router-icon-white.svg"
          alt="router-icon"
        />
        <span v-if="cpeName"
        >
          {{ cpeName }}
        </span>
        <span v-else
          class="tooltip-text"
          v-tooltip.top-center="{ content: 'CPE ID: ' + props.row.cpe }"
        >
          {{ $t('clients.tableNotFound') }}
        </span>
      </span>
    </div>

    <div v-if="cpeName"
      class="cpe-cell-block cpe-cell-block_advanced"
    >
      <span class="badge badge-light badge-info" v-if="!isTypeWired">
        {{ props.row.nativeData.freq }} {{ $t('aps.ghz') }}
      </span>
      <span class="badge badge-light badge-info" v-if="!isTypeWired">
        {{ props.row.nativeData.channel }}
      </span>
      <span class="badge badge-light badge-info" v-if="!isTypeWired">
        {{ props.row.nativeData.rssi }} {{ $t('aps.dbm') }}
      </span>
      <span class="badge badge-light badge-info" v-else>
        {{ $t('clients.tableInterface') }}: {{ props.row.nativeData.radio_id }}
      </span>
    </div>
  </div>

  <span v-else>{{ $t('clients.tableNoData')}}</span>
</template>

<script>
/**
 * компонент - ячейка cpe.
 * компонент локальный: используется только в ClientRFTable.vue
 * @component
 */

import commonService from '@/services/commonService';

export default {
  name: 'CPECell',
  components: {},
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  computed: {
    cpeName() {
      const cpeNamebyId = commonService.cpeNamebyId(this.props.row.cpe);
      return cpeNamebyId;
    },
    isTypeWired() {
      return this.props.row.nativeData.Type === 'wired';
    }
  },
  methods: {
    handleCpeNameClick() {
      this.$emit('on-cpe-name-click');
    }
  }
};
</script>

<style lang="css" scoped>
.cpe-cell {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.cpe-cell-block {
  display: flex;
  align-items: center;
  gap: 4px;
}

.cpe-cell-badge {
  transition: var(--transition);
}
/* .cpe-cell-badge:hover {
  opacity: .6;
} */

.router-icon {
  height: 11px;
  vertical-align: text-bottom;
}
</style>
