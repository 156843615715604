<template>
  <div v-if="props.formattedRow[props.column.field]">
    <span class="badge badge-dark">
      <i class="fa fa-user" />
      {{ props.formattedRow[props.column.field] }}
    </span>
  </div>

  <span v-else>-</span>
</template>

<script>
/**
 * компонент - ячейка ip.
 * компонент локальный: используется только в ClientRFTable.vue
 * @component
 */

export default {
  name: 'IPCell',
  components: {},
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  methods: {
  }
};
</script>

<style lang="css" scoped>
</style>
