<template>
  <span v-if="typeof props.row.snrP80 === 'number'">
    {{ props.row.snrP80 }} {{ $t('aps.dbm') }}
  </span>
  <span v-else>-</span>
</template>

<script>
/**
 * компонент - ячейка snrP80.
 * компонент локальный: используется только в ClientRFPTable.vue
 * @component
 */

export default {
  name: 'SnrP80Cell',
  components: {},
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  filters: {
    round(value) {
      return value.toFixed(0);
    }
  },
  computed: {
  },
  methods: {
  }
};
</script>

<style lang="css" scoped>
</style>
