<template>
  <span>
    {{ macName ? macName : '-' }}
  </span>
</template>

<script>
/**
 * компонент - ячейка mac.
 * компонент локальный: используется только в ClientRFPTable.vue
 * @component
 */

import helpers from '@/helpers';

export default {
  name: 'MacCell',
  components: {},
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  computed: {
    macName() {
      return helpers.getFormatMac(this.props.row.mac);
    }
  },
  methods: {
  }
};
</script>

<style lang="css" scoped>
.muted {
  color: var(--gray-light);
}
</style>
