<template>
  <span v-if="props.row.nativeData.Type !== 'wired'"
    class="badge badge-light"
    :class="{
      'badge-primary': wlanName,
      'badge-default': !wlanName
    }"
  >
    <i class="fa fa-wifi"></i>
    <span v-if="wlanName">
      {{ wlanName }}
    </span>
    <span v-else
      class="tooltip-text"
      v-tooltip.top-center="{ content: 'WLAN ID: ' + props.row.wlan }"
    >
      {{ $t('clients.tableNotFound') }}
    </span>
  </span>
  <span v-else class="muted">—</span>
</template>

<script>
/**
 * компонент - ячейка wlan.
 * компонент локальный: используется только в ClientRFTable.vue
 * @component
 */

import commonService from '@/services/commonService';

export default {
  name: 'WlanCell',
  components: {},
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  computed: {
    wlanName() {
      const wlanNamebyId = commonService.wlanNamebyId(this.props.row.wlan);
      return wlanNamebyId;
    },
  },
  methods: {
    handleCpeNameClick() {
      this.$emit('on-cpe-name-click');
    }
  }
};
</script>

<style lang="css" scoped>
.muted {
  color: var(--gray-light);
}
</style>
