<template>
  <WTable
    style="height: calc(70vh)"
    max-height="calc(70vh - 100px)"
    :columns="columns"
    :rows="rows"
    :isDisabled="isDisabled"
    :bordered="false"
    :withToggleColumnVisibilityMode="!isOneColMode"
    :line-numbers="!isOneColMode"
    :row-style-class="rowStyleClassFn"
    :sort-options="{
      enabled: true,
    }"
    :pagination-options="{
      enabled: false, // isOneColMode // info: отключена из-за неовместимости с легаси
      remoteMode: true,
      mode: 'pages', // pages, records
      position: 'bottom',
      perPage: 10,
      perPageDropdownEnabled: true,
      perPageDropdown: [5, 10, 20, 40, 50],
      dropdownAllowAll: true,
      jumpFirstOrLast : true,
      firstLabel: $t('general.pagination.firstPage'),
      lastLabel: $t('general.pagination.lastPage'),
      nextLabel: $t('general.pagination.nextPage'),
      prevLabel: $t('general.pagination.prevPage'),
      rowsPerPageLabel: $t('general.pagination.rowsPerPage'),
      pageLabel: $t('general.pagination.page'),
      ofLabel: $t('general.pagination.of'),
      allLabel: $t('general.pagination.all')
    }"
    :isLoading.sync="isDataLoading"
    @update:columns="handleUpdateColumns"
    @on-selected-rows-change="handleSelectedRowsChange"
    @on-select-all="handleSelectedAllRowsChange"
    @on-cell-click="handleCellClick"
    @on-page-change="handlePageChange"
    @on-per-page-change="handlePerPageChange"
    @on-sort-change="onSortChange"
  >

    <template v-slot:table-menu>
      <slot name="table-menu"></slot>
    </template>

    <template v-slot:emptystate>
      <slot name="emptystate"></slot>
    </template>

    <template v-slot:table-row="props">
      <template v-if="props.column.field === 'mac'">
        <MacCell :props="props" />
      </template>

      <template v-else-if="props.column.field === 'snrAvg'">
        <SnrAvgCell :props="props" />
      </template>

      <template v-else-if="props.column.field === 'snrP20'">
        <SnrP20Cell :props="props" />
      </template>

      <template v-else-if="props.column.field === 'snrP80'">
        <SnrP80Cell :props="props" />
      </template>

      <template v-else-if="props.column.field === 'signalP10'">
        <SignalP10Cell :props="props" />
      </template>

      <template v-else-if="props.column.field === 'signalP90'">
        <SignalP90Cell :props="props" />
      </template>

      <template v-else>
        {{ props.formattedRow[props.column.field] ? props.formattedRow[props.column.field] : '-' }}
      </template>
    </template>

    <template v-slot:table-actions-bottom v-if="!isOneColMode">
      <slot name="table-actions-bottom"></slot>
    </template>

  </WTable>
</template>

<script>
/**
 * компонент - таблица статистики по ClientRFP.
 * компонент локальный: используется только в ClientRF.vue
 * подробнее по настройкам и пропсам - см компонент WTable.vue
 * @component
*/

import {
  generateTableColumns,
  generateTableRows
} from './config';

import {
  MacCell,
  SnrAvgCell,
  SnrP20Cell,
  SnrP80Cell,
  SignalP10Cell,
  SignalP90Cell
} from './components';

export default {
  name: 'ClientRFPTable',
  components: {
    MacCell,
    SnrAvgCell,
    SnrP20Cell,
    SnrP80Cell,
    SignalP10Cell,
    SignalP90Cell
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    activeRowClientRFPMac: {
      type: String,
      default: ''
    },
    selectedRowClientRFPIds: {
      type: Array,
      required: true
    },
    isDataLoading: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isOneColMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isTableColumnHidden: {
        mac: false,
        snrAvg: false,
        snrP20: false,
        snrP80: false,
        signalP10: false,
        signalP90: false
      }
    };
  },
  watch: {
  },
  computed: {
    columns: {
      get() {
        return generateTableColumns({
          i18nInstance: this.$i18n,
          hiddenColumnRules: {
            mac: this.isTableColumnHidden.mac,
            snrAvg: this.isTableColumnHidden.snrAvg || this.isOneColMode,
            snrP20: this.isTableColumnHidden.snrP20 || this.isOneColMode,
            snrP80: this.isTableColumnHidden.snrP80 || this.isOneColMode,
            signalP10: this.isTableColumnHidden.signalP10 || this.isOneColMode,
            signalP90: this.isTableColumnHidden.signalP90 || this.isOneColMode
          },
          additionalRules: {}
        });
      },
      set(updatedColumns) {
        // обновить таблицу, когда обновились данные в колонках
        // например, какую-то колонку скрыли/раскрыли
        updatedColumns.forEach(el => {
          this.isTableColumnHidden[el.field] = el.hidden;
        });
      }
    },
    rows() {
      return generateTableRows({
        clientRFPData: this.items,
        selectedRowClientRFPIds: this.selectedRowClientRFPIds
      });
    }
  },
  methods: {
    handleUpdateColumns(columns) {
      this.columns = columns;
    },
    rowStyleClassFn(row) {
      const rowMac = row.nativeData.mac;

      return this.activeRowClientRFPMac === rowMac ? 'clients-rfp-table__active-row' : '';
    },
    handleSelectedAllRowsChange(event) {
      this.$emit('on-select-all', event);
    },
    handleSelectedRowsChange(event) {
      const { selectedRows } = event;
      this.$emit('on-selected-rows-change', selectedRows);
    },
    handleCellClick(event) {
      this.$emit('on-cell-click', event.row);
    },
    handlePageChange(event) {
      this.$emit('on-page-change', event);
    },
    handlePerPageChange(event) {
      this.$emit('on-per-page-change', event);
    },
    onSortChange(event) {
      this.$emit('on-sort-change', event);
    }
  },
  created() {
  },
  mounted() {
  }
};
</script>

<style lang="css" scoped>
</style>

<style lang="css">
.clients-rfp-table__active-row {
  background: color-mix(in srgb, var(--brand-success) 70%, transparent);
  color: var(--font-color-main);
}

.clients-rfp-table__active-row:hover {
  background: color-mix(in srgb, var(--brand-success) 70%, transparent) !important;
  color: var(--font-color-main) !important;
}
</style>
