/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/**
 * конфигурация таблицы ClientRFPTable.vue
*/

/**
 * генератор колонок таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/column-options.html#label
*/

function generateTableColumns({ i18nInstance, hiddenColumnRules, additionalRules }) {
  const $t = i18nInstance.t.bind(i18nInstance);

  return [
    {
      label: $t('clients.tableTab_mac'),
      field: 'mac',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.mac
    },
    {
      label: $t('clientsRF.snrAvg'),
      field: 'snrAvg',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.snrAvg
    },
    {
      label: 'SNR P20',
      field: 'snrP20',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.snrP20
    },
    {
      label: 'SNR P80',
      field: 'snrP80',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.snrP80
    },
    {
      label: $t('clientsRF.signalP10'),
      field: 'signalP10',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.signalP10
    },
    {
      label: $t('clientsRF.signalP90'),
      field: 'signalP90',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.signalP90
      // visibilityDropdownSettings: {
      //   hidden: !areLocationsVisible
      // }
    }
  ];
}

/**
 * генератор строк таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/#rows
 * https://xaksis.github.io/vue-good-table/guide/advanced/grouped-table.html#customizing-header-row
*/
function generateTableRows({ clientRFPData, selectedRowClientRFPIds }) {
  return clientRFPData.map(clientRFP => {
    const {
      mac,
      mac_addr,
      signal_p,
      snr_avg,
      snr_max,
      snr_min,
      snr_p
    } = clientRFP;

    return {
      // nativeData - хранит неформатированные данные. Нужны, чтобы удобно эмитить их по событию для дальнейших манипуляций.
      nativeData: clientRFP,
      mac,
      snrAvg: snr_avg,
      snrP20: snr_p?.p20,
      snrP80: snr_p?.p80,
      signalP10: signal_p?.p10,
      signalP90: signal_p?.p90
    };
  });
}

export {
  generateTableColumns,
  generateTableRows
};
