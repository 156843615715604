<template>
  <span>
    {{ props.row.description ? props.row.description : '-' }}
  </span>
</template>

<script>
/**
 * компонент - ячейка description.
 * компонент локальный: используется только в ClientRFTable.vue
 * @component
 */

export default {
  name: 'DescriptionCell',
  components: {},
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  methods: {
  }
};
</script>

<style lang="css" scoped>
</style>
