<template>
  <div
    class="authentication-cell"
    :class="[props.row.authentication ? '' : 'muted']"
  >
    {{ props.row.authentication ? props.row.authentication : $t('clientsRF.authNo') }}
    <AuthenticationTooltip v-if="props.row.authentication" :props="props" />
  </div>
</template>

<script>
/**
 * компонент - ячейка authentication.
 * компонент локальный: используется только в ClientRFTable.vue
 * @component
 */

import {
  AuthenticationTooltip
} from './components';

export default {
  name: 'AuthenticationCell',
  components: {
    AuthenticationTooltip
  },
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  computed: {
    uaType() {
      return this.props.row.nativeData.ua_type;
    },
    uaDevice() {
      return this.props.row.nativeData.ua_device;
    },
    osName() {
      return this.props.row.nativeData.os;
    },
    osVersion() {
      return this.props.row.nativeData.os_version;
    },
    userAgent() {
      return this.props.row.nativeData.useragent;
    }
  },
  methods: {
  }
};
</script>

<style lang="css" scoped>
.authentication-cell {
  display: flex;
  align-items: center;
  gap: 4px;
}
.muted {
  color: var(--gray-light);
}
</style>
