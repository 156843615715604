// selected checkbox of a row or not
function getSelectedRowValue(bssid, selectedBssidsArray) {
  let value = false;

  selectedBssidsArray.forEach(currentSelectedBsSid => {
    if (bssid === currentSelectedBsSid) {
      value = true;
    }
  });

  return value;
}

export {
  getSelectedRowValue
};
