/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/**
 * конфигурация таблицы ClientRFTable.vue
*/

/**
 * генератор колонок таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/column-options.html#label
*/

import {
  getSelectedRowValue
} from './utils';

function generateTableColumns({ i18nInstance, hiddenColumnRules, additionalRules }) {
  const $t = i18nInstance.t.bind(i18nInstance);

  return [
    {
      label: $t('clients.tableTab_mac'),
      field: 'mac',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.mac
    },
    {
      label: 'IP',
      field: 'ip',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.ip
    },
    {
      label: $t('clientsRF.authTable'),
      field: 'authentication',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.authentication
    },
    {
      label: $t('clients.tableTab_description'),
      field: 'description',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.description
    },
    {
      label: $t('clients.tableTab_cpe'),
      field: 'cpe',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.cpe
    },
    {
      label: $t('clients.tableTab_wlan'),
      field: 'wlan',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.wlan
      // visibilityDropdownSettings: {
      //   hidden: !areLocationsVisible
      // }
    }
  ];
}

/**
 * генератор строк таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/#rows
 * https://xaksis.github.io/vue-good-table/guide/advanced/grouped-table.html#customizing-header-row
*/
function generateTableRows({ clientRFData, selectedRowClientRFIds }) {
  return clientRFData.map(clientRF => {
    const {
      State,
      Type,
      channel,
      cpe_id,
      create_at,
      data,
      first_connect,
      freq,
      health_score,
      id,
      in_kbytes,
      in_packets,
      ip,
      last_auth,
      last_connect,
      mac,
      mac_addr,
      manufacturer,
      mode,
      noise,
      os,
      os_version,
      out_kbytes,
      out_packets,
      radio_id,
      rssi,
      snr,
      timestamp,
      ua_device,
      ua_type,
      useragent,
      username,
      wlan_id,
      wlan_ssid
    } = clientRF;

    return {
      // nativeData - хранит неформатированные данные. Нужны, чтобы удобно эмитить их по событию для дальнейших манипуляций.
      nativeData: clientRF,
      mac,
      ip,
      authentication: username,
      description: data?.description,
      cpe: cpe_id,
      wlan: wlan_id,
      // можно ли выбирать строку чекбоксом
      // vgtDisabled: false,
      // выбрана ли строка в чекбоксе исходя из стейта, который контролирует выбранные строки
      vgtSelected: getSelectedRowValue(id, selectedRowClientRFIds)
    };
  });
}

export {
  generateTableColumns,
  generateTableRows
};
