<template>
  <div
    class="with-badge-status active"
    :class="[
      props.row.nativeData.State === 'CONNECTED' && 'active',
      props.row.nativeData.State === 'DISCONNECTED' && 'inactive'
    ]"
  >
    <i class="fa"
      :class="{
        'fa-plug': props.row.nativeData.Type === 'wired',
        'fa-video-camera': props.row.nativeData.Type === 'camera',
        'fa-mobile': props.row.nativeData.Type === 'other'
      }"
    />
    <a
      v-if="
          props.row.nativeData.Type === 'camera' &&
          props.row.nativeData.data &&
          props.row.nativeData.data.rtsp_stream &&
          props.row.nativeData.State === 'CONNECTED'
        "
      href
      @click.stop.prevent="handleClickVideoMacCell"
    >
      {{ macName }}
    </a>
    <span v-else>
      {{ macName }}
    </span>
  </div>
</template>

<script>
/**
 * компонент - ячейка mac.
 * компонент локальный: используется только в ClientRFTable.vue
 * @component
 */

import helpers from '@/helpers';

export default {
  name: 'MacCell',
  components: {},
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  computed: {
    macName() {
      return helpers.getFormatMac(this.props.row.mac);
    }
  },
  methods: {
    handleClickVideoMacCell() {
      this.$emit('on-click-video-mac-cell');
    }
  }
};
</script>

<style lang="css" scoped>
.with-badge-status {
  display: flex;
  align-items: center;
  gap: 8px;
}

.with-badge-status::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 1px solid var(--white);
  border-radius: 50%;
}

.with-badge-status.active::before {
  background-color: var(--brand-success);
}

.with-badge-status.inactive::before {
  background-color: var(--gray-light);
}

.muted {
  color: var(--gray-light);
}
</style>
