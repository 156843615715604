<template>
  <v-popover
    trigger="hover"
    placement="top-center"
    popoverClass="clientrf-table-popover"
    :popperOptions="{
      modifiers: {
        preventOverflow: {
          boundariesElement: 'window'
        }
      }
    }"
  >
    <i class="fa fa-info-circle clientrf-table-icon" role="button" />
    <template slot="popover">
      <ul class='clientrf-table-popover-content list-unstyled text-left m-0'>
        <li class='mb-q'>
          <b>
            {{$t('clientsRF.authInfo')}}
          </b>
        </li>
        <li>
          <small>
            <b>{{ $t('clientsRF.authUsername') }}</b>: {{ props.row.authorization }}
          </small>
        </li>
        <li>
          <small>
            <b>{{ $t('clientsRF.authType') }}</b>: {{ uaType }}
          </small>
        </li>
        <li>
          <small><b>{{ $t('clientsRF.authDevice') }}</b>: {{ uaDevice }}</small>
        </li>
        <li>
          <small><b>{{ $t('clientsRF.authOs') }}</b>: {{ osName }}</small>
        </li>
        <li>
          <small><b>{{ $t('clientsRF.authOsVersion') }}</b>: {{ osVersion }}</small>
        </li>
        <li>
          <small><b>{{ $t('clientsRF.authUseragent') }}</b>: {{ userAgent }}</small>
        </li>
      </ul>
    </template>
  </v-popover>
</template>

<script>
/**
 * компонент - тултип колонки AuthenticationCell.
 * компонент локальный: используется только в AuthenticationCell.vue
 * @component
 */

export default {
  name: 'AuthenticationTooltip',
  components: {},
  props: {
    props: {
      props: {
        type: Object,
        required: true
      }
    }
  },
  computed: {
    uaType() {
      return this.props.row.nativeData.ua_type;
    },
    uaDevice() {
      return this.props.row.nativeData.ua_device;
    },
    osName() {
      return this.props.row.nativeData.os;
    },
    osVersion() {
      return this.props.row.nativeData.os_version;
    },
    userAgent() {
      return this.props.row.nativeData.useragent;
    }
  },
  methods: {
  }
};
</script>

<style lang="css">
.clientrf-table-popover {
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

.clientrf-table-popover-content  {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.clientrf-table-icon {
  color: var(--brand-info);
  transition: var(--transition);
}

.clientrf-table-icon:hover {
  color: var(--brand-primary);
}
</style>
