<template>
  <div><router-view></router-view></div>
</template>

<script>
import Vue from 'vue';
import ruleService from '../../services/ruleService';

export default {
  name: 'Monitor',

  created() {
    ruleService.getRules(this);
  }
};
</script>

<style lang="scss">
.monitor {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.monitor-item {
}

.monitor-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.monitor-link.active {
  color: #167495;
}

.monitor_img {
  width: 40px;
  opacity: 0.5;
}

.monitor-link.active .monitor_img {
  opacity: 1;
}
</style>
