var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:({
    content: ("cpe id: " + (_vm.props.row.nativeData.id)),
    popperOptions: {
      modifiers: {
        preventOverflow: {
          boundariesElement: 'window'
        }
      }
    }
  }),expression:"{\n    content: `cpe id: ${props.row.nativeData.id}`,\n    popperOptions: {\n      modifiers: {\n        preventOverflow: {\n          boundariesElement: 'window'\n        }\n      }\n    }\n  }",modifiers:{"top-center":true}}],class:[!_vm.props.row.name && 'muted']},[_vm._v(" "+_vm._s(_vm.props.row.name ? _vm.props.formattedRow[_vm.props.column.field] : _vm.$t('misc.no_name'))+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }