<template>
  <span :class="[!locationName && 'muted']">
    {{ locationName ? locationName : '-' }}
  </span>
</template>

<script>
/**
 * компонент - ячейка location.
 * компонент локальный: используется только в WifiAnalyzerApsTable.vue
 * @component
 */

import commonService from '@/services/commonService';

export default {
  name: 'LocationCell',
  components: {},
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  computed: {
    locationName() {
      const showLocationFromId = commonService.showLocationFromId(this.props.row.location);
      return showLocationFromId;
    }
  },
  methods: {
  }
};
</script>

<style lang="css" scoped>
.muted {
  color: var(--gray-light);
}
</style>
