var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('WTable',{staticStyle:{"height":"calc(70vh)"},attrs:{"max-height":"calc(70vh - 100px)","columns":_vm.columns,"rows":_vm.rows,"isDisabled":_vm.isDisabled,"bordered":false,"withToggleColumnVisibilityMode":!_vm.isOneColMode,"line-numbers":!_vm.isOneColMode,"row-style-class":_vm.rowStyleClassFn,"sort-options":{
    enabled: true,
  },"pagination-options":{
    enabled: false, // isOneColMode // info: отключена из-за неовместимости с легаси
    remoteMode: true,
    mode: 'pages', // pages, records
    position: 'bottom',
    perPage: 10,
    perPageDropdownEnabled: true,
    perPageDropdown: [5, 10, 20, 40, 50],
    dropdownAllowAll: true,
    jumpFirstOrLast : true,
    firstLabel: _vm.$t('general.pagination.firstPage'),
    lastLabel: _vm.$t('general.pagination.lastPage'),
    nextLabel: _vm.$t('general.pagination.nextPage'),
    prevLabel: _vm.$t('general.pagination.prevPage'),
    rowsPerPageLabel: _vm.$t('general.pagination.rowsPerPage'),
    pageLabel: _vm.$t('general.pagination.page'),
    ofLabel: _vm.$t('general.pagination.of'),
    allLabel: _vm.$t('general.pagination.all')
  },"isLoading":_vm.isDataLoading},on:{"update:isLoading":function($event){_vm.isDataLoading=$event},"update:is-loading":function($event){_vm.isDataLoading=$event},"update:columns":_vm.handleUpdateColumns,"on-selected-rows-change":_vm.handleSelectedRowsChange,"on-select-all":_vm.handleSelectedAllRowsChange,"on-cell-click":_vm.handleCellClick,"on-page-change":_vm.handlePageChange,"on-per-page-change":_vm.handlePerPageChange,"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-menu",fn:function(){return [_vm._t("table-menu")]},proxy:true},{key:"emptystate",fn:function(){return [_vm._t("emptystate")]},proxy:true},{key:"table-row",fn:function(props){return [(props.column.field === 'mac')?[_c('MacCell',{attrs:{"props":props},on:{"on-click-video-mac-cell":function($event){return _vm.handleClickVideoMacCell(props.row.nativeData.data.rtsp_stream)}}})]:(props.column.field === 'ip')?[_c('IPCell',{attrs:{"props":props}})]:(props.column.field === 'authentication')?[_c('AuthenticationCell',{attrs:{"props":props}})]:(props.column.field === 'description')?[_c('DescriptionCell',{attrs:{"props":props}})]:(props.column.field === 'cpe')?[_c('CPECell',{attrs:{"props":props}})]:(props.column.field === 'wlan')?[_c('WlanCell',{attrs:{"props":props}})]:[_vm._v(" "+_vm._s(props.formattedRow[props.column.field] ? props.formattedRow[props.column.field] : '-')+" ")]]}},(!_vm.isOneColMode)?{key:"table-actions-bottom",fn:function(){return [_vm._t("table-actions-bottom")]},proxy:true}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }