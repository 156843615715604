/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/**
 * конфигурация таблицы WifiAnalyzerApsTable.vue
*/

/**
 * генератор колонок таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/column-options.html#label
*/

import {
  getSelectedRowValue
} from './utils';

function generateTableColumns({ i18nInstance, hiddenColumnRules, additionalRules }) {
  const $t = i18nInstance.t.bind(i18nInstance);
  const { areLocationsVisible } = additionalRules;

  return [
    {
      label: $t('general.model'),
      field: 'model',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.model
    },
    {
      label: $t('general.name'),
      field: 'name',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.name
    },
    {
      label: $t('general.location'),
      field: 'location',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.location,
      visibilityDropdownSettings: {
        hidden: !areLocationsVisible
      }
    },
    {
      label: '',
      field: 'connection',
      width: 'auto',
      type: 'text',
      sortable: false,
      hidden: hiddenColumnRules.connection,
      // нельзя скрыть/показать этот столбец
      visibilityDropdownSettings: {
        hidden: true
      }
    }
  ];
}

/**
 * генератор строк таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/#rows
 * https://xaksis.github.io/vue-good-table/guide/advanced/grouped-table.html#customizing-header-row
*/
function generateTableRows({ apsData, selectedRowApsIds }) {
  return apsData.map(ap => {
    const {
      base_location,
      config,
      config_not_send,
      config_status,
      connected,
      description,
      first_connection,
      id,
      last_connection,
      last_disconnection,
      last_error,
      latitude,
      longitude,
      model,
      name,
      state,
      tags
    } = ap;

    return {
      // nativeData - хранит неформатированные данные. Нужны, чтобы удобно эмитить их по событию для дальнейших манипуляций.
      nativeData: ap,
      model: model?.name,
      name,
      location: base_location,
      connection: connected,
      // можно ли выбирать строку чекбоксом
      // vgtDisabled: false,
      // выбрана ли строка в чекбоксе исходя из стейта, который контролирует выбранные строки
      vgtSelected: getSelectedRowValue(id, selectedRowApsIds)
    };
  });
}

export {
  generateTableColumns,
  generateTableRows
};
